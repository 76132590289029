import React from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { AppRoutes, FeatureController } from '@app';
import {
  AccountProvider,
  ApiAccountAdapter,
  ApiAuthAdapter,
  ApiProvider,
  AuthProvider,
  FeatureGuardProvider,
  GlobalStyle,
  UIProvider,
} from '@knowns/common';

const ApplicationProviders: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <UIProvider>
    <ApiProvider>
      <FeatureGuardProvider>
        <AccountProvider>
          <AuthProvider>
            <ApiAuthAdapter />
            <ApiAccountAdapter />
            <FeatureController />
            {children}
          </AuthProvider>
        </AccountProvider>
      </FeatureGuardProvider>
    </ApiProvider>
  </UIProvider>
);

const App: React.FC = () => {
  return (
    <ApplicationProviders>
      <AppRoutes />
    </ApplicationProviders>
  );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <HelmetProvider>
    <Helmet>
      <title>knowns 消費者リサーチ</title>
    </Helmet>
    <React.StrictMode>
      <GlobalStyle />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </HelmetProvider>
);
