import { FC } from 'react';
import {
  APP_URL,
  getEnv,
  getPublicData,
  MenuBar,
  MenuHomeIcon,
  MenuNotificationIcon,
  MenuOriginalGenreIcon,
  MenuQuestionIcon,
  MenuResearchIcon,
  MenuSettingIcon,
} from '@knowns/common';
import styled from 'styled-components';

const serviceName = getEnv('SERVICE_NAME');
const logoPath = getPublicData('logo.svg');
const environment = process.env.REACT_APP_ENVIRONMENT;

export const AppMenuBar: FC = () => {
  return (
    <StyledAppMenuBar>
      <MenuBar
        logo={<img src={logoPath} alt={serviceName} width={48} height={48} />}
        serviceName={serviceName}
      >
        <MenuBar.Section>
          <MenuBar.Link name='ホーム' link={APP_URL.TOP} exact={APP_URL.TOP} Icon={MenuHomeIcon} />
          {/* 本番環境のみオリジナルジャンルを非表示 */}
          {environment !== 'production' && (
            <MenuBar.Link
              name='オリジナルジャンル'
              link={APP_URL.ORIGINAL_GENRE}
              match={[APP_URL.ORIGINAL_GENRE, APP_URL.ANALYTICS_OG_BASE]}
              Icon={MenuOriginalGenreIcon}
            />
          )}
          {/* 本番環境のみカジュアルリサーチの作成が完了するまで非表示 */}
          {environment !== 'production' && (
            <MenuBar.Link
              name='カジュアルリサーチ'
              link={APP_URL.CASUAL_RESEARCH}
              exact={APP_URL.CASUAL_RESEARCH}
              Icon={MenuResearchIcon}
            />
          )}
        </MenuBar.Section>

        <MenuBar.Bottom>
          <MenuBar.Section>
            <MenuBar.Link
              name='お知らせ'
              link={APP_URL.NOTIFICATIONS}
              match={APP_URL.NOTIFICATIONS}
              Icon={MenuNotificationIcon}
            />
            <MenuBar.Link
              name='ヘルプ検索'
              link={APP_URL.HELP}
              exact={APP_URL.HELP}
              Icon={MenuQuestionIcon}
            />
            <MenuBar.Link
              name='設定'
              link={APP_URL.SETTINGS}
              match={APP_URL.SETTINGS}
              Icon={MenuSettingIcon}
            />
          </MenuBar.Section>
        </MenuBar.Bottom>
      </MenuBar>
    </StyledAppMenuBar>
  );
};

const StyledAppMenuBar = styled.div`
  height: 100vh;
  padding: 8px;
`;
